
.createheader {
  background-color: white;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.create-wrapper {
  max-width: 860px;
  margin: 0 auto;
  margin-top: 60px;
}

.createbox-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  margin-bottom: 24px;
}

.createbox {
  width: calc((100% / 3) - 20px);
  height: 80px;
  border-radius: 5px;
  border: 3px dashed #DDD;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createbox:hover {
  background-color: #fff;
}

.createbox1 {
  border: 3px solid #DDD;
  background-color: #0063FA;
  color: #fff;
  font-size: 20px;
}
.createbox1:hover {
  background-color: #0063FA;
}

.create-sidebar {
  width: 180px;
  background-color: #f0f0f0;
  padding: 10px;
  box-sizing: border-box;
  float: left;
  margin-top: 8px;
  text-align: left;
  font-size: 15px;
}

.create-main {
  margin-left: 200px;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
}

.create-content {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #DDD;
}
.creatorpage {
  position: absolute;
  width: 100%;
  top: 0px;
}
.createwizard {
  margin: 0px !important;
}
.createelements {
  padding: 0px !important;
}
ul {
  list-style: none;
  padding-left: 0px;
}
.sideicon {
  border: none;
  color: #777;
}
.fa-pen, .fa-trash, .fa-plus, .fa-check {
  color: #BBB;
  cursor: pointer;
}
.addcourse {
  background-color: transparent;
  width: 133px;
  text-align: left !important;
  border-bottom: 1px solid #bbb !important;
}
.addcourse:focus, .addcourse:focus, .addcourse:focus{
  outline: none;
  background-color: #f7f7f7;
}
.addcourseitem {
  width: 100%;
  text-align: left;
}
.h2sections {
  display: inline-block;
  font-size: 18px;
}
.course-textarea {
  background-color: red;
}
.editorpreview {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  text-align: left;
  padding: 20px;
}
li {
  cursor: pointer;
}