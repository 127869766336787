.applabeldatasource3 {
  margin-top: 20px;
  position: relative;
  display: block;
  float: right;
}
.scheduleappselect {
  height: 25px !important;
  width: 152px !important;
  float: right;
  font-size: 12px !important;
}
.schedule-timedelay {
  height: auto !important;
  display: inline-block;
}
.schedule-dropdown {
  margin-top: 12px !important;
}
.scheduleelements {
  background-color: #f7f7f7 !important;
}
.schedulegpth1 {
  color: #007AFB;
  margin-bottom: 0px;
}
#schedulecommand-wrap {
  visibility: hidden;
  height: 1px;
}
.schedulelabelblack {
  color: #111;
  margin-bottom: 20px;
  display: inline-block;
} 
.fa-circle-info {
  color: #111;
}
.schedulezapierlabel {
  color: #a1a1a1;
  font-style: italic;
}
.placeholderInputhidden {
  height: 25px;
}
.creatorsaveapp.schedulebutton {
  width: 100%;
  border: 1px solid #DDD;
}
.deleteapp.schedulebutton {
  height: 34px !important;
  width: 147px !important;
}
.apptxtarea:disabled {
  background-color: #f2f2f2; /* light gray */
  color: #666; /* dark gray */
  font-style: italic;
}
.schedulesw {
  background-color: #f7f7f7 !important;
}
.runtime-exists {
  background-color: cornsilk;
  font-weight: 600;
}
.checkmark {
  background-color: #ccc;
  border-color: #000;
}
.checkmarkticked {
  background-color: #222;
}
#select-dropdown {
  width: 100% !important;
}
#select-dropdown.select-dropdown {
  width: 227px !important;
}
@media only screen and (max-width: 705px){
.atbottom {
    /* bottom: -11px; */
    height: 7.5vh !important;
    /* position: relative; */
    bottom: -15px !important;
    top: auto!important;
}}
@media only screen and (max-width: 705px){
.chat-input-holder.big, .chat-input-holder.small {
    height: 11.5vh !important;
    bottom: 3px !important;
    position: absolute !important;
}
}