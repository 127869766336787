.poststyling {
  padding: 30px;
  margin: 10px;
  color: #000;
  font-size: 15px;
  border: 1px solid lightgray;
  border-radius: 8px;
  box-shadow: rgb(100 100 111 / 9%) 0px 2px 19px 0px;
  background-color: #fff;
  margin-top: 8px;
}


/* Hide the default radio button */
.post[type="radio"] {
  display: none;
}

/* Style the label to display the image */
.post-image {
  display: inline-block;
  width: 20px; /* set the width and height to match your image */
  height: 20px;
  background-size: contain;
  border: none;
  cursor: pointer;
  opacity: 0.25;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.post1 {
  background-image: url('https://openapps.ai/img/twitter.png');
}
.post2 {
  background-image: url('https://openapps.ai/img/facebook.png');
}
.post3 {
  background-image: url('https://openapps.ai/img/linkedin.png');
}

/* Style the selected state of the radio button */
.post[type="radio"]:checked + .post-image {
  background-color: #fff;
  opacity: 1;
}
#researchdata {
  background-color: #fff;
  color: #000;
  width: 100%;
  max-width: 590px;
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  height: 215px;
  margin: 0 auto;
  position: relative;
  font-size: 14px;
  line-height: 1.5;
  display: block;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.researchcommand {
  height: 50px !important;
  border: 1px solid #ccc;
}
#researchdata::placeholder {
  color: #666;
  font-style: italic;
  font-size: 13px;
  line-height: 1.8;
}
.researchcommand::placeholder {
  color: #666 !important;
  font-style: italic;
  font-size: 13px;
  line-height: 1.8;
}
.researchform {
  height: 498px !important;
}
#researchdata[disabled] {
  background-color: #e0e0e0;
  color: #9e9e9e;
}
#textareauserprompt[disabled] {
  background-color: #e0e0e0;
  color: #9e9e9e;
}
.creatorsubmit[disabled] {
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
  display: none;
}
#toggleButton[disabled] {
  pointer-events: none;
  cursor: not-allowed;
}

#researchdata:hover {
border: 3px dashed #DDD;
}
.dragging {
border: 3px dashed lightslategrey !important;
background-color: #eee !important;
}

.fileswrapper {
  background-color: transparent;
  position: relative;
  height: 42px;
  width: 80%;
  float: right;
  margin: 20px;
  margin-top: -77px;
  white-space: nowrap;
  overflow-x: scroll;
  margin-right: 19px;
}
.fileswrapper[disabled] {
  opacity: 0.5;
  pointer-events: none;
}
.fileswrapper::-webkit-scrollbar {
  height: 4px;
}
/* Handle */
.fileswrapper::-webkit-scrollbar-thumb {
  background: #ccc; 
}
/* Handle on hover */
.fileswrapper::-webkit-scrollbar-thumb:hover {
  background: #ccc; 
}
#files {
  height: 46px;
  display: inline-block;
}
#clearuploaded{
  display: inline-block;
  cursor: pointer;
  padding: 10px;
}
#file {
  border: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  background-color: lightslategray;
  padding-left: 14px;
  padding-right: 14px;
  display: inline-block;
  font-size: 10px;
  color: #fff;
  height: 28px;
  padding-top: 8px;
}
.AddFiles {
  cursor: pointer;
  margin: 0 auto;
  margin-top: -17px;
  width: 100%;
  display: block;
  position: relative;
  padding-bottom: 20px;
  text-align: right;
  max-width: 555px;
}
.AddFiles[disabled] {
  opacity: 0.2;
  pointer-events: none;
}

.apptxtarea {
  background: #fff;
  color: #111;
  display: block;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #cecece;
  margin: 0 auto !important;
  height: 98px;
  line-height: 1.5;
  padding: 10px;
}
.apptxtarea::placeholder {
  color: #666;
  font-style: italic;
  font-size: 14px;
}
#textareauserprompt {
  display: none !important;
}
.appname {
  background: transparent;
  border: none;
  font-size: 37px;
  text-align: center;
  height: 52px;
  color: #0063FA !important;
  font-weight: 600;
  margin-bottom: -13px;
  margin-top: 46px;
}
.appnametxtarea {
  height: 60px;
  padding: 10px;
}
.applabel {
  color: #fff;
  margin-top: 16px;
  font-size: 14px;
}
.applabel2 {
  display: inline-block;
  float:none;
  padding-right: 10px;
}
.fa-circle-info, .fa-pen  {
  cursor: pointer;
  color: #fff;
}
#targetInput::placeholder {
  color: #666;
}
.labelplaceholderinput {
  cursor: pointer;
  display: inline-block;
  text-align: left;
}
.labelwrap {
  padding-bottom: 5px;
  text-align: left;
}


.formelelementshidden {
  max-height: 0;
  overflow: hidden;
}

.formelements.formelelements {
  max-height: none;
}
.formelementscreatormode {
  background-color: teal !important;
  position: absolute;
  left: 0px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 226px;
  width: 100%;
  height: 1153px;
}
.pricingelements {
  position: absolute;
  left: 0px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 226px;
  width: 100%;
  height: 864px;
}
.formelementsschedule {
  background-color: #f1f1f1 !important;
}
.innerelements {
  max-width: 645px;
  margin: 0 auto;
  margin-top: -18px;
  height: 820px;
  padding-bottom: 20px;
}
#toggleButton {
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
  opacity: 0.1;
  text-align: center;
}
#toggleButton:hover {
  opacity: 1;
}
#toggleButton .fa-pen {
  color: #000;
}
.youcantconnectthedotslookingfwd {
  width: 50px;
}
.youcanonlyconnectthemlookingbackwards {
  margin-left: -212px;
}
.creatorsaveapp {
  background-color: #fff;
  color: #333;
  margin-bottom: -5px !important;
}
.SaveEdit{
text-decoration: underline;
color: #fff;
}
#appdisplayname {
  color: #000;
  user-select: none;
}
.editappbutton {
  background-color: white;
  border: none;
  border-radius: 7px;
  color: #111;
  cursor: pointer;
  display: inline-block!important;
  font-size: 11px;
  height: 23px;
  line-height: 22px;
  margin: 0px 0px 33px!important;
  opacity: 1!important;
  width: 78px;
  vertical-align: bottom;
  text-align: center;
  box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 3%), 0 6.7px 5.3px rgb(0 0 0 / 2%), 0 2.5px 5px rgb(0 0 0 / 2%), 0 2.3px 17.9px rgb(0 0 0 / 2%), 0 1.8px 33.4px rgb(0 0 0 / 3%), 0 3px 7px rgb(0 0 0 / 4%);
  user-select: none;
}
.editappbutton:hover {
  background-color: teal !important;
  color: #fff;
}
.slightlyhidden {
  background-color: teal !important;
  opacity: 0.3 !important;
}
.editapph1 {
  color: #fff;
  margin-bottom: 0px;
}
.swfull {
  height: 100vh !important;
  max-height: 100vh !important;
  /* position: absolute; */
  width: calc(100% - 170px);
  display: block;
  overflow-y: scroll;
}
.swfull.closed {
  width: 100%;
}
.swfull.open {
  width: calc(100% - 170px);
}
.appwizard {
  margin-top: -58px !important;
  width: 100% !important;
  max-width: 860px !important;
  padding-bottom: 4px;
}
#placeholderInput {
  height: 60px;
  text-align: left;;
}
#promptquestiontextarea, #promptanswertextarea {
  height: 60px;
  background-color: cornflowerblue;
  color: #fff;
}
#textareauserprompt::placeholder {
  color: #666;
}
#textareauserprompt {
  padding: 20px;
  line-height: 1.5;
  margin-top: 38px !important;
  margin-bottom: -20px !important;
  max-width: 720px;
}
.resetapp {
  color: #000;
  cursor: pointer;
  margin-right: 0px !important;
  padding-left: 0px !important;
  margin-top: 13px !important;
  opacity: 0.5;
  position: relative !important;
}
#resetwizard.hidden {
  display: none !important;
}
.chat-input-holder-hidden {
  display: none !important;
}
.circle {
  display: grid;
}
#placeholderInput {
  padding-left: 10px;
  position: relative;
  z-index: 2;
}
.placeholderInputhidden {
  visibility: hidden;
}
.deleteapp {
  width: 55px;
  padding-top: 14px;
  display: inline-block;
  margin-top: 22px;
  margin-left: 5px;
  opacity: 0.6;
  position: relative;
  vertical-align: bottom;
  height: 37px;
  line-height: 10px;
  padding: 12px;
  font-size: 14px;
}
.deleteapp:hover {
  opacity: 1;
}
.fwpostcreator {
  height: 390px !important;
}
.appsplus {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #fff;
  display: inline-block;
  padding-top: 4px;
  padding-left: 6px;
  padding-right: 6px;
}
.appslabel:hover {
  text-decoration: underline;
  cursor: pointer;
}
.customappicon {
  background: #fff;
  height: 26px;
  width: 27px;
  padding: 5px;
  margin: 5px;
  border-radius: 7px;
  font-size: 15px;
  display: table-caption;
}
.customappicon:hover {
  opacity: 0.8;
}
.customappicon img {
  width: 14px;
}
.customappholder {
  display: inline-block;
}
.iconwrap {
  text-align: left;
  height: 48px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 289px;
}
.iconwrap::-webkit-scrollbar {
  height: 4px;
}
/* Handle */
.iconwrap::-webkit-scrollbar-thumb {
  background: cornflowerblue; 
}
/* Handle on hover */
.iconwrap::-webkit-scrollbar-thumb:hover {
  background: cornflowerblue; 
}

input[type="radio"]:checked + label {
  /* Add your extra CSS styles here */
  box-shadow: 0 0 0 4px #1dc198;
  margin-left: 5px;
  margin-top: 4px;
}
input[type="radio"]:checked + label.post-image {
  /* Add your extra CSS styles here */
  outline: 3px solid #1DC198;
  border-radius: 5px;
}

.iconlabel {
  border: none;
  background-color: #fff;
  color: #007AFB;
  text-align: center;
  padding-top: 6px;
  height: 32px;
  width: 32px;
  font-size: 17px;
  margin-bottom: 13px;
  margin: 0px;
  margin-top: 5px;
  margin-right: 10px;
  display: inline-block;
}
.iconinput {
  visibility: hidden;
  position: absolute;
}


.tooltip {
  position: relative;
}

.tooltip:hover::before {
  content: attr(title);
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  bottom: 50%;
  left: 170px;
  transform: translateX(-50%);
  display: block;
  z-index: 10;
  width: 400px;
  margin: 0 auto;
}
.tooltip:focus::before {
  content: attr(title);
  position: relative;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  bottom: 100%;
  right: 50%;
  transform: translateX(-50%);
  display: block;
  z-index: 10;
  width: 400px;
  margin: 0 auto;
}
.tooltip:hover::after {
  visibility: hidden;
}
#zapierhook {
  font-size: 22px;
  padding-left: 15px;
  text-align: left;
}
#zapierhook::placeholder{
  color: rgb(135, 135, 135);
  font-size: 22px;
  font-weight: normal;
}
.apptxtarea.invalid {
  background-color: #fff;
}
.apptxtarea.valid {
  background-color: #fff;
  font-weight: bold;
  color: darkgreen;
}
.invaliddiv {
  display: none;
}
.validdiv {
  display: inline-block;
  float: right;
  margin-top: -36px;
  margin-right: 8px;
  font-size: 27px;
  color: forestgreen;
  position: relative;
}
.invaliddiv2 {
  display: inline-block;
  float: right;
  margin-top: -36px;
  margin-right: 8px;
  font-size: 27px;
  color: grey;
  position: relative;
  opacity: 0.4;
}
.validdiv2 {
  display: none;
}
#select-dropdown {
  font-size: 22px;
  width: 227px;
  height: 50px;
  background: white;
  color: black;
  border-radius: 4px;
  float: right;
  margin-right: 1px;
  margin-top: -4px;
  border: 1px solid #DDD;
}
.select-dropdownwrapper {
  width: 100%;
  height: 124px;
}
.dropboxinner {
  width: 289px;
  float: right;
  font-size: 34px;
  margin: 0 auto;
  margin-top: 20px;
}
.deleteapp.schedulebutton {
  height: 80px;
  width: 88px !important;
}
.creatorsaveapp.schedulebutton {
  margin: 0 auto;
  display: block;
  border: none;
  height: 74px;
  border-radius: 5px;
  padding: 10px;
  line-height: 20px;
  margin-top: 47px;
  width: 276px;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  margin-bottom: 53px;
  height: 80px;
  padding: 0px;
  width: 238px;
}
.schedulebutton:hover {
  opacity: 0.8;
}
.schedulewizard {
  padding: 0px !important;
  margin-top: 65px !important;
}
.scheduleinnerelements {
  margin-top: 11px;
}
select#appDropdown {
  font-size: 18px;
  background-color: white;
  color: #222;
  /* height: 80px; */
  border-radius: 4px;
  /* width: 100%; */
  border-bottom: 1px solid #fff;
  margin-bottom: 15px;
  outline: none;

  height: 25px !important;
  width: 152px !important;
  float: right;
  font-size: 12px !important;
}
.automationssubhead{
  color: #fff;
  line-height: 34px;
  font-size: 22px;
  padding-bottom: 30px;
  margin-top: -40px;
}
.swautomations {
  padding-bottom: 200px;
}
#h1createapp {
  padding-bottom: 20px;
}
.mainhead {
  color: #333;
  font-size: 55px;
  font-weight: 600;
  line-height: 60px;
  margin-top: 19px;
  position: relative;
  display: block;
}
.applist-top {
  flex: none;
  height: 58px;
}
.applistsubhead {
  font-size: 20px;
  margin-top: -19px;
  padding-bottom: 6px;
  line-height: 28px;
}
#scheduled_command {
  height: 76px;
}
.automationssubhead a {
  color: #fff;
}
.automationcreateapplink {
  position: relative;
    margin: 0 auto;
    display: block;
    margin-top: -74px;
    text-align: center;
    width: 120px;
    color: #000;
    background: orange;
    float: right;
    height: 40px;
    border-radius: 8px;
    line-height: 40px;
    margin-right: 40px;
    font-weight: bold;
}
.integrationapps {
  color: #fff;
  padding: 10px;
  font-size: 30px;
  padding-bottom: 41px;
}
#outsidedata {
  height: 48px;
  background-color: mediumaquamarine;
}
.outsidedataover {
  margin-top: -36px;
  position: relative;
  text-align: left;
}
.applabeldatasource {
  margin-top: 18px;
  position: relative;
}
.applabeldatasource2 {
  margin-top: -39px;
  position: relative;
  display: block;
}
.react-switch-bg {
  background: mediumaquamarine !important;
}
.outsidedatatogglewrapper {
  /* margin-top: 49px !important; */
  margin-top: 1px !important;
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: right;
  height: 6px;
}
.outsidedplaceholdertogglewrapper {
  margin-top: 6px !important;
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: right;
  margin-right: 19px;
}
.checkmark {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: cornflowerblue;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
  border: 1px solid #fff;
  vertical-align: text-bottom;
  z-index: 3;
  padding: 0px !important;
}
.checkmark:hover {
  opacity: 0.8;
}

.checkmark::after {
  content: "";
  position: absolute;
  visibility: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
}

input[type="checkbox"]:checked + .checkmark::after {
  display: block;
}
.checkboxdatasource{
visibility: hidden;
}
.closedatasource {
  float: right;
  margin-top: -39px;
  position: relative;
  margin-right: 8px;
  background: none;
  border: none;
  font-size: 25px;
  color: #333;
  cursor: pointer;
  z-index: 3;
}
.xeroxparc1979 {
  /* padding: 21px; */
  border-radius: 100%;
  color: #0063FA;
  height: 79px;
  width: 79px;
  position: relative;
  display: inline-block;
  vertical-align: text-bottom;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(249,249,249,1) 100%);
  margin: 0 auto;
}
.xeroxparc1979-wrapper {
  display: inline-block;
  margin-top: 5px;
  vertical-align: middle;
  background-color: #0063FA;
  width: 79px;
  margin: 0 auto;
  border-radius: 100%;
  height: 101px;
  margin-left: 5px !important;
}
.xeroxparc1979-wrapper.notpublic {
/* height: 84px; */
height: 79px;
}
.xeroxwrapperwrapped {
  display: inline-block;
  vertical-align:baseline;
}
.appcreatorheadingwrap {
  width: 100%;
  text-align: center;
  padding-top: 46px;
  flex-wrap: wrap;
  height: 306px;
}
.appcreatormenu {
  margin-top: -28px;
  padding-bottom: 10px;
}
.demoplaceholder {
  font-style: italic;
}

.submitter-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
#like-button {
  border: none;
  background-color: transparent;
  background-image: url(https://openapps.ai/img/thumb1.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 13px;
  width: 15px;
  height: 15px;
  vertical-align: baseline;
  margin-top: 1.5px;
  margin-left: 3px;
}
#like-button:hover {
  cursor: pointer;
  background-position: 0px -16px;
  background-repeat: no-repeat;
}
.likeswrapper {
  width: 79px;
  font-size: 14px;
  margin: 0 auto;
  padding-left: 3px;
  margin-top: 1px;
}
.likeswrapper:hover {
  cursor: default;
}
.saveb {
  width: 178px;
  margin-left: 6px !important;
  margin-right: 6px !important;
}
#submitter-close {
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 14px;
  font-size: 24px;
  opacity: 0.5;
  color: #666;
}
.alreadypublic {
  background-color: #0063FA;
  color: #fff;
  cursor: default;
}
.alreadypublic:hover {
  background-color: #0063FA !important;
  opacity: 1 !important;
}
#submitNameButton {
  display: block;
  color: #fff;
  background-color: #0063FA;
  width: 200px;
  margin: 0 auto;
  height: 41px;
  line-height: 41px;
  border-radius: 2px;
  margin-bottom: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 14px;
}
.submitnamemodal {
  background: #efefef;
  max-width: 300px;
  height: 269px;
  font-size: 12px;
  color: #333;
}
.submitnamemodal.open {
  left: 85px;
}
.submitterInput {
  height: 34px;
  padding: 5px;
  padding-left: 8px;
  width: 200px;
  margin-top: 12px;
  font-size: 15px;
  margin-bottom: -3px;
}
.submitterInput::placeholder {
  font-style: italic;
  color: #666;
}
.makePublicHeader {
  font-size: 20px;
  color: #0063FA;
  margin-top: -1px;
  font-weight: bold;
  margin-bottom: 8px;
}
.creatorname {
  display: block;
  width: 100%;
  color: #555;
  font-size: 12px;
  font-style: italic;
  margin-top: 12px !important;
  margin-bottom: -37px !important;
}
.customlogobg {
  height: 100%;
  margin: 0px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  border-radius: 100%;
  z-index: 6;
  position: absolute;
  background-color: #0063FA;
}
.noncustomlogobg {
  height: 79px;
  width: 79px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: white;
  padding: 10px;
  font-size: 32px;
  padding-top: 21px;
}
.customlogobgbutton {
  height: 24px;
  width: 24px;
  display: inline-block;
  vertical-align: sub;
  padding: 0px;
  margin: 0px;
  margin-left: 2px;
}
.customlogobgbutton-wrapper {
  height: 100%;
  display: inline-block
}
.customcustomlogobg {
  /* margin: 0 auto; */
  height: 32px;
  width: 32px;
  vertical-align: bottom;
  background-size: cover;
  margin-right: 25px;
  background-color: #fff;
}
.uploadicon {
  padding-right: 5px;
  color: #007AFB;
  height: 16px;
  vertical-align: text-top;
}
#custom-icon[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background: #fff;
  border-radius: 5px;
  height: 32px;
  margin-left: 49px;
  margin-top: 3px;
}
.custom-file-upload:hover {
opacity: 0.8;
}
.appinndercustom {
  background-color: #fff;
}
.appcustomside {
  height: 100%;
  width: 100%;
  background: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.appouterblue {
  display: inline-block;
}
.applistapp {
  background: transparent;
  font-size: 24px;
  margin: 0px;
  padding-top: 22px;
  width: 100%;
  height: 100%;
}
.customlist {
  padding: 0px;
  width: 51px;
  height: 51px;
  position: relative;
  margin: 0px;
  margin-top: 10px;
}
.likes {
  color: #ccc;
  display: inline-block;
  float: right;
  font-size: 12px;
  margin-top: 53px;
  position: absolute;
  margin-left: -12px;
}
.likescustom {
  margin-left: 1px;
}
.underthetiffanylamp {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  margin-top: -42px;
}
/* #formWizard-automation {
  height: 100vh;
} */
.tips {
  position: absolute;
  left: 101px;
  margin-top: -7px !important;
  width: 64px;
  display: none !important;
}
.runagainshow.tips.byhidden {
  display: none !important;
}
.runagainshow.tips {
  display: inline-block !important;
}
.runagainshow {
  display: inline-block !important;
}
.byhidden {
  display: none !important;
}
.poststyling2 {
  background-color: #fefefe;
  width: 100%;
  left: 0px;
  position: relative;
  /* padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 20px;
  padding-right: 20px; */
}
.mchat {
  padding-top: 27px;
  padding-bottom: 21px;
  padding-left: 25px;
  text-align: right;
  padding-right: 60px;
}
.runagain {
  width: 109px;
  height: 26px;
  line-height: 5px;
  margin-top: -8px;
  position: relative;
  float: right;
  font-size: 12px;
  margin-right: 15px;
  display: none;
}
.circlehide {
  opacity: 0;
}
.circleshow {
  opacity: 0.3;
}
.swrap-noborder {
  border: none;
}
.schedulewizard-open {
  overflow: scroll;
  height: 900px;
  margin-top: -45px !important;
}
.costlabel {
  color: #007afb;
  float: right;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: -8px;
  margin-top: 6px;
  text-align: right;
}
.costlabel2 {
  bottom: 5px;
  right: 13px;
  position: absolute;
  color: #555;
  font-size: 14px;
}
.costlabel3 {
  margin-top: 10px;
}
.wholeearthcatalogue74backcover {
  width: 120px;
}
.appnamemargin {
  margin-top: 38px;
  position: relative;
  height: 52px !important;
}
.appcreatorwizard {
  position: relative !important;
  margin-top: -31px !important;
  max-width: 100% !important;
}
.resetwrapper {
  float: right;
  margin-bottom: -92px;
  margin-top: 41px;
  position: relative;
  width: 295px;
  right: 44px;
}
.resetwrapperouter {
  height: 38px;
  margin: 0 auto;
  max-width: 840px;
}
.logobutton {
  width: 23px;
  height: 21px;
  display: inline-block;
  vertical-align: sub;
  margin-right: 8px;
  margin-left: 4px;
}
svg.svg-inline--fa.fa-dollar-sign {
  font-size: 11px;
  margin-right: -1px;
}
.tipoverlay {
  position: absolute;
  height: 100vh;
  width: 100%;
  color: #111;
  z-index: 8;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  z-index: 5;
}
.closepay {
  width: 100%;
  text-align: right;
  cursor: pointer;
  margin-top: -8px;
  padding-bottom: 10px;
  opacity: 0.4;
}
.lightgreen {
  background-color: lightgreen;
}
.green {
  background-color: green !important;
}
.app_idtipinput {
  visibility: hidden;
  height: 1px;
  position: absolute;
}
.modalsuccess {
  font-size: 32px;
  color: #0063FA;
  padding-bottom: 31px;
  display: block;
  font-weight: 400;
}
.modelbody {
  padding: 31px;
  margin-bottom: 23px;
  display: block;
}
.acmsmall {
  margin-top: -30px;
}
.greentips {
  background-color: gold;
  color: #000;
  font-weight: bold;
}
.shareheader {
  font-size: 18px;
  font-weight: bold;
  color: #0063FA;
  padding-top: 15px;
}
.sharepublic {
  margin-top: 10px !important;
  margin-bottom: 2px !important;
  background-color: #0063FA;
  color: #fff;
  height: 30px;
  width: 200px;
  line-height: 30px;
  font-size: 16px;
}
.just99ballmer86 {
  color: #fff;
  background-color: #007AFB;
  line-height: 50px;
  height: 50px;
  margin-bottom: 33px;
}
.bucklingkeys {
  margin-bottom: -29px;
  font-weight: 400;
  line-height: 30px;
}
.paytable {
  width: 100%;
  height: 182px;
  margin-bottom: 20px;
  margin-top: 35px;
}
.thepaycolumn {
  display: inline-grid;
  width: 45%;
  text-align: left;
  background: floralwhite;
  height: 100%;
  border-radius: 10px;
  padding: 22px;
  align-content: baseline;
  opacity: 0.45;
  cursor: pointer;
  height: 200px;
  position: relative;
  line-height: 26px;
}
#swoz {
  margin-right: 25px;
  margin-bottom: 18px;
}
.freeoption {
  opacity: 1;
  border: 4px dashed #9D9D9D;
  height: 200px;
}
.denttheuniverse {
  opacity: 1;
  border: 4px dashed #9D9D9D;
  height: 200px;
}
.footerappsicon {
  cursor: default !important;
  color: #fff;
}
.appfoot {
  background-color: #0063FA;
  padding-left: 6px;
}
.appfoot:hover {
  opacity: 1;
}
.footer {
  /* Set initial position off the bottom of the screen */
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  background-color: cornsilk;
  border-top: 1px solid #ccc;
  padding: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  z-index: 9999;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.15);
  color: #333;

  /* Add transition */
  transition: bottom 2s ease;
}

/* Show the footer by setting bottom to 0 */
.footer.show {
  bottom: 0;
  padding-right: 20px;
}


.footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.footer ul li {
  margin-right: 10px;
}

.footer a {
  color: #0063FA;
  font-weight: bold;
  text-decoration: none;
  width: 100%;
  max-width: 297px;
  position: absolute;
  right: 20px;
}
.footer a:hover {
  text-decoration: underline;
}

.footer-close {
  cursor: pointer;
  margin-left: 10px;
  display: none;
}

.footer.show {
  bottom: 0;
}
.dontlose {
  text-align: left;
  width: 100%;
  max-width: 80px;
  margin-left: 30px;
  font-size: 14px;
  margin-right: 7px;
}
.footerblueboxwozpope {
  width: 100%;
  max-width: 217px;
  text-align: left;
}
#loginemail, #loginpassword {
  color: #000;
  height: 61px;
  display: block;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 8px;
  padding-left: 20px;
}
#loginemail::placeholder, #loginpassword::placeholder {
  color: #555;
}
.loginh1 {
  color: teal;
  margin-bottom: 30px;
}
.resetquestion {
  float: right;
  color: #888;
  cursor: pointer;
}
.loginform {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}


.slider-builder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  width: 100%;
}

.steps {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}


.step {
  flex: auto;
  flex-direction: column-reverse; /* change to column-reverse */
  align-items: center;
  margin: 0 20px;
}

.label {
  margin-bottom: 5px;
  font-weight: bold;
}

input[type='text'] {
  padding: 5px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid black;
  text-align: center;
}

.slider {
  position: relative;
  width: 55%;
  height: 40px;
}

.step-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.step-label {
  font-size: 12px;
  color: #333;
  text-align: center;
}

.step-input {
  width: 30px; /* adjust the width */
  height: 20px;
  margin: 0 5px;
  text-align: center;
  font-size: 12px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f9f9f9;
}

.add-remove-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.add-button,
.remove-button {
  width: 80px;
  height: 30px;
  margin: 0 5px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-button:hover,
.remove-button:hover {
  background-color: #0069d9;
}
input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #ddd;
  border-radius: 25px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #999;
  margin-top: -5px;
  cursor: pointer;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #ddd;
  border-radius: 25px;
}

input[type=range]::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #999;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #ddd;
  border-radius: 50px;
}

input[type=range]::-ms-fill-upper {
  background: #ddd;
  border-radius: 50px;
}

input[type=range]::-ms-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #999;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
.addStepButton {
  position: relative;
  right: 0px;
  margin-left: 22px;
}
.sliderText{
  font-size: 15px !important;
  background-color: transparent;
  max-width: 80px;
  color: #fff;
  font-weight: bold;
  border-bottom: 1px dashed #ccc !important;
}
.step-name {
  cursor: pointer;
  font-weight: 500;
}
.applabelslider {
  right: 0px;
    position: relative;
    width: 100%;
    text-align: right;
}
.removeButton {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
}
.docscontent {
  text-align: left;
  line-height: 1.55;
}
.h1docs {
  text-align: center;
}
.h2docs {
  margin-bottom: 0px;
  margin-top: 50px;
}
.docsblock {
  margin-top: 1px;
  margin-bottom: 14px;
}
.yellowtxt {
  color: yellow !important;
}
.loginbutton {
  margin-bottom: 10px !important;
}
.inspiration {
  width: 154px;
  height: 20px;
  display: inline-grid;
  color: #0063FA;
  margin-top: -151px;
  position: relative;
  text-align: center;
}
.inspprev {
  float: left;
  cursor: pointer;
  position: absolute;
  left: 0px;
  opacity: 0.6;
}
.inspnext {
  float: right;
  cursor: pointer;
  right: 0px;
  position: absolute;
  opacity: 0.6;
}
.inspprev:hover, .inspnext:hover {
  opacity: 1;
}
.inspmiddle {
  display: inline-block;
}
.stayhungry a {
  color: #fff;
  font-size: 12px;
  text-decoration: none;
}
.stayhungry a:hover {
  opacity: 0.8;
  text-decoration: underline;
}
.novis {
  visibility: hidden;
  height: 1px !important;
}
.scheduleAppNameDiv {
  font-size: 10px;
  text-align: left;
  margin-top: 10px;
  padding-left: 10px;
}
.scheduledMessageDiv {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  background-color: initial;
  font-size: 10px;
}
.schedulelabel {
  margin-top: 15px;
  display: block;
}
.selectedMessageDiv {
  color: #fff;
  font-size: 12px;
  margin-top: 10px;
}
.react-flow {
  overflow: visible !important;
}
.react-flow__node.react-flow__node-default.nopan.selectable {
  font-size: 10px;
  text-align: left;
}
.react-flow .react-flow__edge-textwrapper text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.react-flow .react-flow__edges {
  max-width: 200px !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  text-align: left !important;

}
.react-flow__edge-textbg {
  fill: none !important;
}
.scheduleh1 {
  margin-top: -29px;
  margin-bottom: 49px;
}
.react-flow__viewport {
  left: calc(50% - 78px) !important;
}
.schedulefull {
  max-width: 100%;
}
.prevbtn {
  display: inline-block;
  position: absolute;
  left: -29px;
  border: none;
  cursor: pointer;
}
.nxtbtn {
  display: inline-block;
  position: absolute;
  right: -29px;
  border: none;
  cursor: pointer;
}
.YouCantSeeTheDotsLookingFwdsOnlyBckwards {
  width: 100%; 
}
.YouCantSeeTheDotsLookingFwdsOnlyBckwards a {
  text-decoration: none;
  color: #222;
}
.sideclock {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.loadedmsgfromcopy {
  padding: 20px;
}
.face-grimace {
  color: #CCC;
  height: 14px;
  vertical-align: sub;
}
.sharecommission {
  color: #000;
  font-weight: normal;
  font-size: 12px;
  margin-top: 12px;
}
section#scroll-wrapper {
    max-width: 646px;
    margin: 0 auto;
}
.uchat {
  padding-top: 21px;
  padding-bottom: 21px;
  line-height: 1.4;
  padding-right: 8px;
}
.sidebargpticon {
  width: 25px;
  height: 25px;
  background-size: contain;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  margin-left: -3px;
}
.sidebargpticon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.sidebargpticon.with-image {
  background-image: none;
}
.hasread-badge {
  height: 14px;
  width: 14px;
  background-color: red;
  color: white;
  border-radius: 100%;
  text-align: center;
  padding-top: 1px;
  margin-left: 15px;
  margin-top: 15px;
  font-size: 10px;
}
.toggleNotification {
  position: absolute;
  color: #ccc;
  font-size: 18px;
  cursor: pointer;
  margin-top: -7px;
  margin-bottom: 2px;
  display: block;
  top: 12px;
  right: 15px;
  z-index: 2500 !important;
}
#gift {
  display: inline-block;
}


.pulsating-circle {
  width: 79px;
  height: 79px;
}
.pulsating-circle:before {
  content: "";
  position: absolute;
  display: block;
  width: 200%;
  height: 200%;
  box-sizing: border-box;
  margin-left: -50%;
  margin-top: -50%;
  border-radius: 100%;
  background-color: #01a4e9;
  opacity: 0.3;
  -webkit-animation: pulse-ring 1.35s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
          animation: pulse-ring 1.35s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}
.pulsating-circle:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  /* background-color: white; */
  border-radius: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  -webkit-animation: pulse-dot 1.35s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
          animation: pulse-dot 1.35s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@-webkit-keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%, 100% {
    opacity: 0;
  }
}
@-webkit-keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}